import gql from 'graphql-tag';

export const LIST_NOTIFICATIONS = gql`
  query ListNotifications($page: Int, $pageSize: Int) {
    notifications(page: $page, pageSize: $pageSize) {
      totalUnread
      hasMorePages
      notifications {
        id
        context
        type
        title
        body
        pictureUrl
        path {
          id
          type
          name
        }
        occurredOn
        readAt
        isRead
      }
    }
  }
`;
