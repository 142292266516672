import gql from 'graphql-tag';

export const LIST_CLIENT_ACCOUNTS_FOR_CLIENT = gql`
  query ListClientAccountsForClient($clientId: ID!) {
    clientAccountsForClient(clientId: $clientId) {
      items {
        id
        leadProfessional {
          name
        }
        firm {
          id
          referenceNumber
          name
          sraNumber
          logoUrl
        }
        status
      }
    }
    clientAccountStatuses: __type(name: "ClientAccountStatus") {
      name
      enumValues {
        name
      }
    }
  }
`;
