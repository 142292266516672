import gql from 'graphql-tag';

export const LIST_CLIENT_ACCOUNTS_FOR_FIRM = gql`
  query ListClientAccountsForFirm($firmId: ID!) {
    clientAccountsForFirm(firmId: $firmId) {
      items {
        id
        status
        client {
          id
          status
          firstName
          preferredName
          lastName
          pictureUrl
          emailAddress
          mobilePhoneNumber
        }
      }
    }
  }
`;
