import gql from 'graphql-tag';

export const LIST_FIRM_MEMBERSHIPS = gql`
  query ListFirmMemberships($professionalId: ID!) {
    firmMemberships(professionalId: $professionalId) {
      id
      status
      firm {
        id
        referenceNumber
        name
        sraNumber
        logoUrl
      }
    }
    firmMemberStatuses: __type(name: "FirmMemberStatus") {
      name
      enumValues {
        name
      }
    }
  }
`;
