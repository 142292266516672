import gql from 'graphql-tag';

export const GET_TASK = gql`
  query GetTask($taskId: ID!) {
    task(taskId: $taskId) {
      id
      name
      description
      officialAdvice
      sectionCount
      sections {
        id
        name
        description
        officialAdvice
        questions {
          id
          text
          description
        }
        questionCount
      }
      ... on LibraryTask {
        matterTypes
        isPublished
      }
    }
    __type(name: "MatterType") {
      name
      enumValues {
        name
      }
    }
  }
`;
