import gql from 'graphql-tag';

export const REMOVE_INTERNAL_USER = gql`
  mutation RemoveInternalUser($input: RemoveInternalUserInput!) {
    removeInternalUser(input: $input) {
      userId
      removedAt
    }
  }
`;
