import gql from 'graphql-tag';

export const DEFINE_TASK_SECTION = gql`
  mutation DefineTaskSection($input: DefineTaskSectionInput!) {
    defineTaskSection(input: $input) {
      id
      name
      description
      sectionCount
      sections {
        id
        name
        description
        officialAdvice
        ordering
        questions {
          id
          text
          description
        }
        questionCount
      }
    }
  }
`;
