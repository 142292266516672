import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Form, Input, message } from 'antd';
import { CHANGE_TASK_ADVICE } from '../../graphql/tasks/change-task-advice';
import './Forms.scss';

class UpdateTaskOfficialAdviceForm extends PureComponent {
  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={CHANGE_TASK_ADVICE}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          message.success(`The task official advice has been updated successfully`, 4);

          /* Close modal */
          this.props.updateTaskOfficialAdviceModalVisible();
        }}
      >
        {(updateTaskAdvice, { loading, error, data }) => {
          return (
            <Form
              {...layout}
              name="updateTaskSectionOfficialAdvice"
              className="update-task-section-official-advice-form"
              layout="vertical"
              onFinish={(values) => {
                updateTaskAdvice({
                  variables: {
                    input: {
                      taskId: this.props.taskId,
                      officialAdvice: values.officialAdvice,
                    },
                  },
                });
              }}
              initialValues={{
                officialAdvice: this.props.officialAdvice,
              }}
            >
              <Form.Item
                label="Task advice"
                name="officialAdvice"
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 5000) {
                        return Promise.reject('Task advice must be between 1 and 5000 characters');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.TextArea rows="5" />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Updating advice' : 'Update advice'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default UpdateTaskOfficialAdviceForm;
