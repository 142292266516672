import gql from 'graphql-tag';

export const CHANGE_TASK_SECTION_ADVICE = gql`
  mutation ChangeTaskSectionAdvice($input: ChangeTaskSectionAdviceInput!) {
    changeTaskSectionAdvice(input: $input) {
      id
      name
      description
      sections {
        id
        name
        description
        officialAdvice
      }
    }
  }
`;
