import gql from 'graphql-tag';

export const DESCRIBE_TASK_SECTION = gql`
  mutation DescribeTaskSection($input: DescribeTaskSectionInput!) {
    describeTaskSection(input: $input) {
      id
      name
      sections {
        id
        name
        description
      }
    }
  }
`;
