import gql from 'graphql-tag';

export const REMOVE_TASK_SECTION = gql`
  mutation RemoveTaskSection($input: RemoveTaskSectionInput!) {
    removeTaskSection(input: $input) {
      id
      name
      sectionCount
      sections {
        id
        name
        description
      }
    }
  }
`;
