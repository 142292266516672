import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      preferredName
      givenName
      middleName
      familyName
      fullName
      email
      phone
    }
  }
`;
