import gql from 'graphql-tag';

export const PREPARE_TASK = gql`
  mutation PrepareLibraryTask($input: PrepareLibraryTaskInput!) {
    prepareLibraryTask(input: $input) {
      id
      name
      description
      matterTypes
      isPublished
    }
  }
`;
