import gql from 'graphql-tag';

export const EDIT_QUESTION = gql`
  mutation EditQuestion($input: EditQuestionInput!) {
    editQuestion(input: $input) {
      id
      text
      description
      officialAdvice
      parts {
        id
        label
        instructions
        officialAdvice
      }
    }
  }
`;
