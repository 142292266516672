import gql from 'graphql-tag';

export const CHANGE_TASK_MATTER_TYPES = gql`
  mutation ChangeTaskMatterTypes($input: ChangeTaskMatterTypesInput!) {
    changeTaskMatterTypes(input: $input) {
      id
      matterTypes
    }
  }
`;
