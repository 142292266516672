import React, { PureComponent } from 'react';
import { Form, Input, Checkbox, DatePicker } from 'antd';
import './../Forms.scss';
import moment from 'moment';

class DatePart extends PureComponent {
  constructor(props) {
    super(props);

    /* Set some form defaults (workaround) */
    const values = props.form.getFieldsValue();
    if (values?.parts) {
      props.form.setFieldsValue({
        ...values,
        parts: values.parts.map((part, index) => {
          // Check if part is inside a list part and set defaults
          if (props.isList && index === props.parentField.name) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                attributes: part.configuration.attributes.map((attr, attrIndex) => {
                  if (props.field.name === attrIndex) {
                    return {
                      ...attr,
                      configuration: {
                        ...attr.configuration,
                        isRequired: true,
                      },
                    };
                  }
                  return attr;
                }),
              },
            };
          }

          // Set defaults if part is NOT inside a list part
          if (index === props.field.name && !props.isList) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                isRequired: true,
              },
            };
          }
          return part;
        }),
      });
    }

    this.setDate = this.setDate.bind(this);
    this.setEarliestDate = this.setEarliestDate.bind(this);
    this.setLatestDate = this.setLatestDate.bind(this);
  }

  setDate(date, type) {
    const values = this.props.form.getFieldsValue();
    this.props.form.setFieldsValue({
      ...values,
      parts: values.parts.map((part, index) => {
        if (this.props.isList && index === this.props.parentField.name) {
          return {
            ...part,
            configuration: {
              ...part.configuration,
              attributes: part.configuration.attributes.map((attr, attrIndex) => {
                if (this.props.field.name === attrIndex) {
                  return {
                    ...attr,
                    configuration: {
                      ...attr.configuration,
                      [type]: date.format(),
                    },
                  };
                }
                return attr;
              }),
            },
          };
        }
        if (index === this.props.field.name && !this.props.isList) {
          return {
            ...part,
            configuration: {
              ...part.configuration,
              [type]: date.format(),
            },
          };
        }
        return part;
      }),
    });
  }

  setEarliestDate(date) {
    this.setDate(date, 'earliestDate');
  }

  setLatestDate(date) {
    this.setDate(date, 'latestDate');
  }

  render() {
    const dateFormat = 'DD/MM/YYYY';
    const dateTimeFormat = 'DD/MM/YYYY - HH:mm';

    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 250) {
                  return Promise.reject('Must be between 1 and 250 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'First name'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        {!this.props.isList && (
          <Form.Item
            name={[this.props.field.name, 'officialAdvice']}
            label="Official advice for the question part"
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 5000) {
                    return Promise.reject('Must be between 1 and 5000 characters');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea
              placeholder="Provide the official advice for the question part"
              rows={3}
            />
          </Form.Item>
        )}
        <Form.Item
          name={[this.props.field.name, 'configuration', 'useTime']}
          valuePropName="checked"
        >
          <Checkbox>Include time?</Checkbox>
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => (
            <>
              <Form.Item label="Earliest date">
                <DatePicker
                  showToday={false}
                  showTime={
                    form.getFieldValue([
                      'parts',
                      this.props.field.name,
                      'configuration',
                      'useTime',
                    ]) && {
                      defaultValue: moment('00:00:00', 'HH:mm:ss'),
                    }
                  }
                  defaultValue={
                    form.getFieldValue([
                      'parts',
                      this.props.field.name,
                      'configuration',
                      'earliestDate',
                    ]) &&
                    moment.utc(
                      this.props.form.getFieldValue([
                        'parts',
                        this.props.field.name,
                        'configuration',
                        'earliestDate',
                      ])
                    )
                  }
                  onSelect={this.setEarliestDate}
                  format={
                    form.getFieldValue(['parts', this.props.field.name, 'configuration', 'useTime'])
                      ? dateTimeFormat
                      : dateFormat
                  }
                />
              </Form.Item>
              <Form.Item label="Latest date">
                <DatePicker
                  showToday={false}
                  showTime={
                    form.getFieldValue([
                      'parts',
                      this.props.field.name,
                      'configuration',
                      'useTime',
                    ]) && {
                      defaultValue: moment('00:00:00', 'HH:mm:ss'),
                    }
                  }
                  defaultValue={
                    form.getFieldValue([
                      'parts',
                      this.props.field.name,
                      'configuration',
                      'latestDate',
                    ]) &&
                    moment.utc(
                      this.props.form.getFieldValue([
                        'parts',
                        this.props.field.name,
                        'configuration',
                        'latestDate',
                      ])
                    )
                  }
                  onSelect={this.setLatestDate}
                  format={
                    form.getFieldValue(['parts', this.props.field.name, 'configuration', 'useTime'])
                      ? dateTimeFormat
                      : dateFormat
                  }
                  disabledDate={(current) => {
                    return (
                      current &&
                      current <=
                        moment.utc(
                          this.props.form.getFieldValue([
                            'parts',
                            this.props.field.name,
                            'configuration',
                            'earliestDate',
                          ])
                        )
                    );
                  }}
                />
              </Form.Item>
            </>
          )}
        </Form.Item>

        <Form.Item
          name={[this.props.field.name, 'configuration', 'isRequired']}
          valuePropName="checked"
        >
          <Checkbox>Is an answer required?</Checkbox>
        </Form.Item>
      </div>
    );
  }
}

export default DatePart;
