import gql from 'graphql-tag';

export const CHANGE_TASK_ADVICE = gql`
  mutation ChangeTaskAdvice($input: ChangeTaskAdviceInput!) {
    changeTaskAdvice(input: $input) {
      id
      name
      description
      officialAdvice
    }
  }
`;
