import gql from 'graphql-tag';

export const DESCRIBE_TASK = gql`
  mutation DescribeTask($input: DescribeTaskInput!) {
    describeTask(input: $input) {
      id
      name
      description
    }
  }
`;
