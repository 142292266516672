import gql from 'graphql-tag';

export const GET_QUESTION = gql`
  query GetQuestion($questionId: ID!) {
    question(questionId: $questionId) {
      id
      text
      description
      officialAdvice
      parts {
        id
        label
        instructions
        officialAdvice
        type
        ordering
        ... on AddressPart {
          configuration {
            useName
            isRequired
          }
        }
        ... on BooleanPart {
          configuration {
            positiveLabel
            negativeLabel
          }
        }
        ... on ChoicePart {
          configuration {
            minimumChoices
            maximumChoices
            options {
              value
              description
            }
            isRequired
          }
        }
        ... on DatePart {
          configuration {
            earliestDate
            latestDate
            useTime
            isRequired
          }
        }
        ... on EmailPart {
          configuration {
            isRequired
          }
        }
        ... on FilePart {
          configuration {
            maximumFiles
            isRequired
          }
        }
        ... on ListPart {
          configuration {
            isRequired
            minimumItems
            maximumItems
            attributes {
              id
              label
              instructions
              type
              ordering
              ... on AddressAttribute {
                configuration {
                  useName
                  isRequired
                }
              }
              ... on DateAttribute {
                configuration {
                  earliestDate
                  latestDate
                  useTime
                  isRequired
                }
              }
              ... on EmailAttribute {
                configuration {
                  isRequired
                }
              }
              ... on FileAttribute {
                configuration {
                  maximumFiles
                  isRequired
                }
              }
              ... on MoneyAttribute {
                configuration {
                  currency
                  minimumAmount
                  maximumAmount
                  isRequired
                }
              }
              ... on NumberAttribute {
                configuration {
                  minimumValue
                  maximumValue
                  isRequired
                }
              }
              ... on PercentAttribute {
                configuration {
                  minimumValue
                  maximumValue
                  isRequired
                }
              }
              ... on PhoneAttribute {
                configuration {
                  isRequired
                }
              }
              ... on StringAttribute {
                configuration {
                  minimumLength
                  maximumLength
                  isRequired
                }
              }
              ... on TextAttribute {
                configuration {
                  minimumLength
                  maximumLength
                  isRequired
                }
              }
            }
          }
        }
        ... on MoneyPart {
          configuration {
            currency
            minimumAmount
            maximumAmount
            isRequired
          }
        }
        ... on NumberPart {
          configuration {
            minimumValue
            maximumValue
            isRequired
          }
        }
        ... on PercentPart {
          configuration {
            minimumValue
            maximumValue
            isRequired
          }
        }
        ... on PhonePart {
          configuration {
            isRequired
          }
        }
        ... on StringPart {
          configuration {
            minimumLength
            maximumLength
            isRequired
          }
        }
        ... on TextPart {
          configuration {
            minimumLength
            maximumLength
            isRequired
          }
        }
      }
    }
  }
`;
