import gql from 'graphql-tag';

export const CHANGE_TASK_PUBLISH_STATUS = gql`
  mutation ChangeTaskPublishStatus($input: ChangeTaskPublishStatusInput!) {
    changeTaskPublishStatus(input: $input) {
      id
      name
      isPublished
    }
  }
`;
