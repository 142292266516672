import gql from 'graphql-tag';

export const REMOVE_QUESTION = gql`
  mutation RemoveQuestion($input: RemoveQuestionInput!) {
    removeQuestion(input: $input) {
      questionId
      removedOn
    }
  }
`;
