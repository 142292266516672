import gql from 'graphql-tag';

export const REGISTER_INTERNAL_USER = gql`
  mutation RegisterInternalUser($input: RegisterInternalUserInput!) {
    registerInternalUser(input: $input) {
      id
      referenceNumber
      type
      givenName
      middleName
      familyName
      fullName
      email
      phone
      picture
      blocked
      roles {
        id
        name
        description
      }
    }
  }
`;
