import gql from 'graphql-tag';

export const RENAME_TASK_SECTION = gql`
  mutation RenameTaskSection($input: RenameTaskSectionInput!) {
    renameTaskSection(input: $input) {
      id
      name
      sections {
        id
        name
        description
      }
    }
  }
`;
