import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';
import App from './components/App';

if (env('ENVIRONMENT') !== 'development') {
  Sentry.init({
    dsn: env('SENTRY_DSN'),
    release: env('APP_VERSION'),
    environment: env('ENVIRONMENT'),
    debug: true,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
