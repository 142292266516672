import gql from 'graphql-tag';

export const UPDATE_FIRM_MEMBER = gql`
  mutation UpdateFirmMember($input: UpdateFirmMemberInput!) {
    updateFirmMember(input: $input) {
      id
      employeeNumber
      jobTitle
      office
      role
      isActive
      activeSince
      status
      professional {
        id
        status
        referenceNumber
        type
        firstName
        preferredName
        lastName
        emailAddress
        pictureUrl
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
    }
  }
`;
