import gql from 'graphql-tag';

export const REGISTER_FIRM = gql`
  mutation RegisterFirm($input: RegisterFirmInput!) {
    registerFirm(input: $input) {
      id
      type
      referenceNumber
      companyNumber
      name
      sraNumber
      logoUrl
      seatCount
      occupiedSeatCount
      openClientAccountCount
    }
  }
`;
