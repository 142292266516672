import gql from 'graphql-tag';

export const NEW_NOTIFICATION = gql`
  subscription {
    newNotification {
      id
      context
      type
      title
      body
      pictureUrl
      path {
        id
        type
        name
      }
      occurredOn
      readAt
      isRead
    }
  }
`;
