import gql from 'graphql-tag';

export const GET_FIRM_DETAILS = gql`
  query GetFirm($firmId: ID!) {
    firm(firmId: $firmId) {
      id
      type
      referenceNumber
      companyNumber
      name
      logoUrl
      sraNumber
      seatCount
      vacantSeatCount
      occupiedSeatCount
    }
    firmRoles: __type(name: "FirmRole") {
      name
      enumValues {
        name
      }
    }
    firmProfessionalTypes: __type(name: "FirmProfessionalType") {
      name
      enumValues {
        name
      }
    }
    firmProfessionalStatuses: __type(name: "FirmProfessionalStatus") {
      name
      enumValues {
        name
      }
    }
  }
`;
