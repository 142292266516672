import React, { PureComponent } from 'react';
import { Prompt, Redirect } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { Button, Form, Input, Switch, Modal, message } from 'antd';
import { CloseOutlined, CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { UPDATE_USER } from '../../graphql/users/update-user';
import { UPDATE_USER_ACCESS } from '../../graphql/users/update-user-access';
import { REQUEST_PASSWORD_RESET } from '../../graphql/identity/request-password-reset';
import { REMOVE_INTERNAL_USER } from '../../graphql/users/remove-internal-user';
import './Forms.scss';

class UserDetailsForm extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      emailAddress: props.data.user.email,
      userBlocked: props.data.user.blocked,
      unsavedFormChanges: false,
      redirectAfterRemoveUser: false,
    };
  }

  formRef = React.createRef();

  onFormReset = () => {
    this.formRef.current.resetFields();
    this.setState({
      unsavedFormChanges: false,
    });
  };

  saveValuesToState = (val) => {
    this.setState(val);
    this.setState({
      unsavedFormChanges: true,
    });
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    if (this.state.redirectAfterRemoveUser) {
      return <Redirect to="/users" />;
    } else {
      return (
        <>
          <Prompt
            when={this.state.unsavedFormChanges}
            message="If you leave this page you will LOSE ALL CHANGES, are you sure?"
          ></Prompt>
          <Mutation
            mutation={UPDATE_USER}
            onCompleted={(data) => {
              message.success(`Saved changes successfully`, 4);
              this.setState({
                unsavedFormChanges: false,
              });
            }}
            onError={(data) => message.error(`Sorry, an error occurred ${data}`, 4)}
          >
            {(updateUserDetails, { loading, error, data }) => {
              return (
                <Form
                  {...layout}
                  ref={this.formRef}
                  name="UserDetailsForm"
                  className="user-details-form"
                  layout="vertical"
                  initialValues={{
                    firstName: this.props.data.user.givenName,
                    preferredName: this.props.data.user.preferredName,
                    middleName: this.props.data.user.middleName,
                    lastName: this.props.data.user.familyName,
                    emailAddress: this.props.data.user.email,
                    phone: this.props.data.user.phone,
                  }}
                  onValuesChange={this.saveValuesToState}
                  onFinish={(values) => {
                    updateUserDetails({
                      variables: {
                        input: {
                          id: this.props.data.user.id,
                          rename: {
                            givenName: values.firstName,
                            preferredName: values.preferredName,
                            middleName: values.middleName,
                            familyName: values.lastName,
                          },
                          changeEmail: {
                            email: values.emailAddress,
                          },
                          changePhone: {
                            phone: values.phone,
                          },
                        },
                      },
                    });
                  }}
                >
                  <Form.Item
                    label="First name"
                    name="firstName"
                    validateTrigger={['onBlur']}
                    rules={[{ required: true, message: 'First name is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Preferred first name"
                    name="preferredName"
                    validateTrigger={['onBlur']}
                  >
                    <Input spellCheck="false" />
                  </Form.Item>
                  <Form.Item label="Middle name" name="middleName">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last name"
                    name="lastName"
                    validateTrigger={['onBlur']}
                    rules={[{ required: true, message: 'Last name is required' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email address"
                    name="emailAddress"
                    validateTrigger={['onBlur']}
                    rules={[
                      {
                        type: 'email',
                        message: 'Invalid email address',
                      },
                      {
                        required: true,
                        message: 'Email address is required',
                      },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item label="Phone" name="phone">
                    <Input />
                  </Form.Item>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={!this.state.unsavedFormChanges}
                    >
                      {loading ? 'Saving changes' : 'Save changes'}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={this.onFormReset}
                      style={{ marginLeft: 20 }}
                      disabled={!this.state.unsavedFormChanges}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>
              );
            }}
          </Mutation>
          <Mutation
            mutation={REQUEST_PASSWORD_RESET}
            variables={{
              input: {
                email: this.state.emailAddress,
                resetUrl: 'http://app.transparently.legal/reset',
              },
            }}
            onCompleted={(data) => {
              message.success(
                `Successfully sent the password reset link to ${this.state.emailAddress}`,
                4
              );
            }}
            onError={(data) => {
              message.error(`Sorry, an error occurred`, 4);
            }}
          >
            {(sendPasswordReset, { loading, error, data }) => {
              return (
                <>
                  <h2 className="heading mt-40">Reset password</h2>
                  <Button type="primary" onClick={sendPasswordReset} loading={loading}>
                    {loading ? 'Sending password reset' : 'Send password reset'}
                  </Button>
                </>
              );
            }}
          </Mutation>

          <Mutation
            mutation={UPDATE_USER_ACCESS}
            variables={{
              input: {
                id: this.props.data.user.id,
                isBlocked: !this.state.userBlocked,
              },
            }}
            onCompleted={(data) => {
              const { blocked, fullName } = data.updateUserAccess;
              this.setState({
                userBlocked: blocked,
              });
              message.success(
                <span className="u-standardcase">
                  {fullName}&rsquo;s user account was set to {blocked ? 'inactive' : 'active'}
                </span>,
                4
              );
            }}
            onError={(data) => message.error(`Sorry, an error occurred`, 4)}
          >
            {(updateUserAccess, { loading, error, data }) => {
              return (
                <>
                  <h2 className="heading mt-40">Transparently account status</h2>
                  <Switch
                    onClick={updateUserAccess}
                    loading={loading}
                    checked={!this.state.userBlocked}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                  <span className="ml-10">{this.state.userBlocked ? 'inactive' : 'active'}</span>
                </>
              );
            }}
          </Mutation>

          {this.props.data.user.type === 'INTERNAL' && (
            <Mutation
              mutation={REMOVE_INTERNAL_USER}
              variables={{
                input: {
                  id: this.props.data.user.id,
                },
              }}
              onCompleted={(data) => {
                message.success(
                  <span className="u-standardcase">
                    {this.props.data.user.givenName} {this.props.data.user.familyName} has been
                    removed successfully
                  </span>,
                  4
                );
                this.setState({
                  redirectAfterRemoveUser: true,
                });
              }}
              onError={(data) => message.error(`Sorry, an error occurred`, 4)}
            >
              {(removeInternalUser, { loading, error, data }) => {
                const confirmRemoveInternalUser = () => {
                  Modal.confirm({
                    title: 'Remove internal user',
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <span>
                        Are you sure you want to remove
                        <span className="u-standardcase">
                          <b>{` ${this.props.data.user.givenName} ${this.props.data.user.familyName} `}</b>
                          as an internal user?
                        </span>
                      </span>
                    ),
                    centered: true,
                    okText: 'Remove user',
                    cancelText: 'Cancel',
                    onOk: removeInternalUser,
                  });
                };

                return (
                  <>
                    <h2 className="heading mt-40">Internal user</h2>
                    <Button type="primary" onClick={confirmRemoveInternalUser}>
                      Remove internal user
                    </Button>
                  </>
                );
              }}
            </Mutation>
          )}
        </>
      );
    }
  }
}

export default UserDetailsForm;
