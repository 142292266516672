import React, { PureComponent } from 'react';
import { Query, Mutation } from 'react-apollo';
import Loading from '../Loading';
import { PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tag, Switch, message } from 'antd';
import { ReactComponent as IconTasks } from '../../assets/images/icon-tasks.svg';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import { LIST_TASKS } from '../../graphql/tasks/list-tasks';
import { CHANGE_TASK_PUBLISH_STATUS } from '../../graphql/tasks/change-task-publish-status';
import AddTaskForm from '../Forms/AddTaskForm';

class TaskListing extends PureComponent {
  constructor() {
    super();
    this.state = {
      addTaskModalVisible: false,
      matterTypeFiltersArr: [],
    };
  }

  addTaskModalVisible = () => {
    this.setState((prevState) => {
      return { addTaskModalVisible: !prevState.addTaskModalVisible };
    });
  };

  render() {
    // Set up table
    const tableColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '20%',
        sorter: (a, b) => {
          var nameA = !a.name ? '' : a.name.toUpperCase();
          var nameB = !b.name ? '' : b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: '50%',
        render: (text) => {
          return text.length > 50 ? text.slice(0, 50) + '...' : text;
        },
        sorter: (a, b) => {
          var nameA = !a.description ? '' : a.description.toUpperCase();
          var nameB = !b.description ? '' : b.description.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Matter types',
        dataIndex: 'matterTypes',
        width: '30%',
        render: (text, record, index) => {
          const { matterTypes } = record;

          if (matterTypes.length === 0) {
            return (
              <Tag className="u-lowercase" style={{ margin: '0 5px 3px 0' }}>
                ANY
              </Tag>
            );
          }

          return matterTypes.sort().map((matterType) => (
            <Tag key={matterType} className="u-lowercase" style={{ margin: '0 5px 3px 0' }}>
              {matterType}
            </Tag>
          ));
        },
        filters: this.state.matterTypeFiltersArr,
        onFilter: (value, record) => {
          return record.matterTypes.includes(value) || record.matterTypes.length === 0;
        },
      },
      {
        title: 'Published',
        dataIndex: 'isPublished',
        align: 'center',
        width: '5%',
        filters: [
          {
            text: 'Published',
            value: true,
          },
          {
            text: 'Unpublished',
            value: false,
          },
        ],
        onFilter: (value, record) => record.isPublished === value,
        filterMultiple: false,
        render: (published, record, index) => (
          <Mutation
            mutation={CHANGE_TASK_PUBLISH_STATUS}
            variables={{
              input: {
                taskId: record.id,
                isPublished: published ? false : true,
              },
            }}
            onCompleted={(data) => {
              const { name, isPublished } = data.changeTaskPublishStatus;
              message.success(
                <span className="u-standardcase">
                  {`'${name}' has been ${isPublished ? 'published' : 'unpublished'}`}
                </span>,
                4
              );
            }}
            onError={(data) => {
              message.error(`Sorry, an error occurred`, 4);
            }}
          >
            {(changeTaskPublishStatus, { loading, error, data }) => {
              return (
                <>
                  <Switch
                    onClick={(val, e) => {
                      changeTaskPublishStatus();
                      e.stopPropagation();
                    }}
                    loading={loading}
                    checked={published}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </>
              );
            }}
          </Mutation>
        ),
      },
    ];

    return (
      <>
        <Query
          query={LIST_TASKS}
          variables={{
            includeUnpublished: true,
          }}
          onCompleted={(data) => {
            // Create table filters for matter types and add them to state
            const matterTypesArr = data.__type.enumValues.map((matterType) => {
              return matterType.name;
            });

            const matterTypeFilters = matterTypesArr.sort().map((matterType) => {
              return {
                text: matterType,
                value: matterType,
              };
            });

            this.setState({ matterTypeFiltersArr: matterTypeFilters });
          }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) return <p>Error :(</p>;

            return (
              <>
                <div className="listing-header">
                  <h1 className="listing-header__heading">
                    <IconTasks className="listing-header__icon" />
                    Task Library
                  </h1>
                  <Button
                    type="primary"
                    className="listing-header__add-btn"
                    onClick={this.addTaskModalVisible}
                  >
                    <PlusOutlined />
                    Add task
                  </Button>
                </div>
                {/* Task library table */}
                <Table
                  className="task-library-table"
                  dataSource={data.libraryTasks}
                  pagination={{ pageSize: 10 }}
                  columns={tableColumns}
                  rowKey={(record) => record.id}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        this.props.history.push(`/tasks/${record.id}`);
                      },
                    };
                  }}
                />

                {/* Add task to library modal */}
                <Modal
                  title="Add task to library"
                  centered
                  destroyOnClose={true}
                  visible={this.state.addTaskModalVisible}
                  onCancel={this.addTaskModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <AddTaskForm data={data} addTaskModalVisible={this.addTaskModalVisible} />
                </Modal>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}

export default TaskListing;
