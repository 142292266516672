import gql from 'graphql-tag';

export const UPDATE_PROFESSIONAL = gql`
  mutation UpdateProfessional($input: UpdateProfessionalInput!) {
    updateProfessional(input: $input) {
      id
      referenceNumber
      type
      firstName
      preferredName
      lastName
      emailAddress
      pictureUrl
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
  }
`;
