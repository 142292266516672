import gql from 'graphql-tag';

export const UPDATE_FIRM_LOGO = gql`
  mutation UpdateFirmDetails($input: UpdateFirmDetailsInput!) {
    updateFirmDetails(input: $input) {
      id
      logoUrl
    }
  }
`;
