import gql from 'graphql-tag';

export const RENAME_TASK = gql`
  mutation RenameTask($input: RenameTaskInput!) {
    renameTask(input: $input) {
      id
      name
      description
    }
  }
`;
