import gql from 'graphql-tag';

export const GET_USER_LIST = gql`
  query ListUsers($page: Int, $pageSize: Int) {
    users(page: $page, pageSize: $pageSize) {
      items {
        id
        referenceNumber
        type
        preferredName
        givenName
        middleName
        familyName
        fullName
        email
        phone
        picture
        blocked
        roles {
          id
          name
          description
        }
      }
    }
    UserTypes: __type(name: "UserType") {
      name
      enumValues {
        name
      }
    }
  }
`;
