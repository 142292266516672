import gql from 'graphql-tag';

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      token
      expiresIn
      expiresAt
    }
  }
`;
