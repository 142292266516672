import gql from 'graphql-tag';

export const LIST_FIRM_MEMBERS = gql`
  query ListFirmMembers($firmId: ID!) {
    firmMembers(firmId: $firmId) {
      id
      employeeNumber
      jobTitle
      office
      role
      isActive
      activeSince
      status
      professional {
        id
        status
        referenceNumber
        type
        firstName
        preferredName
        lastName
        emailAddress
        pictureUrl
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
    }
  }
`;
