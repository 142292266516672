import gql from 'graphql-tag';

export const GET_AUTH_TOKEN = gql`
  mutation LoginWithPassword($input: LoginWithPasswordInput!) {
    loginWithPassword(input: $input) {
      __typename
      successful
      message
      ... on SuccessfulAuthenticationPayload {
        accessToken
        idToken
        expiresIn
        tokenType
        scope
        user {
          id
          fullName
          email
        }
      }
    }
  }
`;
