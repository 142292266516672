import React, { PureComponent } from 'react';
import { Form, Input, InputNumber, Checkbox, Select } from 'antd';
import './../Forms.scss';
import { CURRENCIES } from '../../../consts';

class MoneyPart extends PureComponent {
  constructor(props) {
    super(props);

    /* Set some form defaults (workaround) */
    const values = props.form.getFieldsValue();
    if (values?.parts) {
      props.form.setFieldsValue({
        ...values,
        parts: values.parts.map((part, index) => {
          // Check if part is inside a list part and set defaults
          if (props.isList && index === props.parentField.name) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                attributes: part.configuration.attributes.map((attr, attrIndex) => {
                  if (props.field.name === attrIndex) {
                    return {
                      ...attr,
                      configuration: {
                        ...attr.configuration,
                        isRequired: true,
                      },
                    };
                  }
                  return attr;
                }),
              },
            };
          }

          // Set defaults if part is NOT inside a list part
          if (index === props.field.name && !props.isList) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                isRequired: true,
              },
            };
          }
          return part;
        }),
      });
    }
  }

  render() {
    const { Option } = Select;
    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 250) {
                  return Promise.reject('Must be between 1 and 250 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'First name'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        {!this.props.isList && (
          <Form.Item
            name={[this.props.field.name, 'officialAdvice']}
            label="Official advice for the question part"
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 5000) {
                    return Promise.reject('Must be between 1 and 5000 characters');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea
              placeholder="Provide the official advice for the question part"
              rows={3}
            />
          </Form.Item>
        )}
        <Form.Item
          name={[this.props.field.name, 'configuration', 'isRequired']}
          valuePropName="checked"
        >
          <Checkbox>Is an answer required?</Checkbox>
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'currency']}
          label="Currency"
          wrapperCol={{ span: 12 }}
          validateTrigger={['onBlur']}
        >
          <Select className="u-standardcase" placeholder="select currency">
            {CURRENCIES.map((currency) => {
              return (
                <Option value={currency.code} className="u-standardcase" key={currency.code}>
                  {currency.code + ' - ' + currency.description}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'minimumAmount']}
          label="Minimum amount"
          wrapperCol={{ span: 12 }}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              required: true,
              message: 'Minimum amount is required',
            },
            {
              validator: (_, value) => {
                if (value < -1000000000 || value > 1000000000) {
                  return Promise.reject(
                    'Minimum amount must be between -1,000,000,000 and 1,000,000,000'
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            placeholder="Minimum"
            style={{ width: '100%' }}
            min={-1000000000}
            max={1000000000}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'maximumAmount']}
          label="Maximum amount"
          wrapperCol={{ span: 12 }}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              required: true,
              message: 'Maximum amount is required',
            },
            {
              validator: (_, value) => {
                if (value < -1000000000 || value > 1000000000) {
                  return Promise.reject(
                    'Maximum amount must be between -1,000,000,000 and 1,000,000,000'
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            placeholder="Maximum"
            style={{ width: '100%' }}
            min={-1000000000}
            max={1000000000}
          />
        </Form.Item>
      </div>
    );
  }
}

export default MoneyPart;
