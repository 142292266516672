import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { ReactComponent as IconFirms } from '../../assets/images/icon-firms.svg';
import { ReactComponent as IconUsers } from '../../assets/images/icon-users.svg';
import { ReactComponent as IconTasks } from '../../assets/images/icon-tasks.svg';
import { ReactComponent as IconNotifications } from '../../assets/images/icon-notifications.svg';
import { ReactComponent as IconLogout } from '../../assets/images/icon-logout.svg';
import './Menu.scss';

class MenuComponent extends PureComponent {
  render() {
    return (
      <Menu mode="inline" className="main-navigation" selectable={false}>
        <Menu.Item key="1">
          <NavLink to="/firms" onClick={this.props.handleMobileMenuClick}>
            <span role="img" className="anticon">
              <IconFirms />
            </span>
            <span>Firms</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/users" onClick={this.props.handleMobileMenuClick}>
            <span role="img" className="anticon">
              <IconUsers />
            </span>
            <span>Users</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/tasks" onClick={this.props.handleMobileMenuClick}>
            <span role="img" className="anticon">
              <IconTasks />
            </span>
            <span>Task Library</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4" onClick={this.props.handleNotificationDrawerVisible}>
          <span
            role="img"
            className={`anticon ${
              this.props.totalUnreadNotifications > 0
                ? 'notification-icon notification-icon--unread'
                : ''
            }`}
          >
            <IconNotifications />
          </span>
          <span>Notifications</span>
        </Menu.Item>
        <div className="menu-divider"></div>
        <Menu.Item key="5" className="logout" onClick={() => this.props.handleLogout(false)}>
          <span role="img" className="anticon">
            <IconLogout />
          </span>
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    );
  }
}

export default MenuComponent;
