import gql from 'graphql-tag';

export const PREPARE_QUESTION = gql`
  mutation PrepareQuestion($input: PrepareQuestionInput!) {
    prepareQuestion(input: $input) {
      id
      text
      description
      officialAdvice
      parts {
        id
        label
        instructions
        officialAdvice
      }
    }
  }
`;
