import gql from 'graphql-tag';

export const GET_FIRM_LIST = gql`
  query ListFirms {
    firms {
      id
      type
      referenceNumber
      companyNumber
      name
      sraNumber
      logoUrl
      seatCount
      occupiedSeatCount
      openClientAccountCount
    }
    FirmTypes: __type(name: "FirmType") {
      name
      enumValues {
        name
      }
    }
  }
`;
