import gql from 'graphql-tag';

export const REMOVE_TASK = gql`
  mutation RemoveTask($input: RemoveTaskInput!) {
    removeTask(input: $input) {
      taskId
      removedOn
    }
  }
`;
