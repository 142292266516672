import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Form, Input, message } from 'antd';
import { REGISTER_INTERNAL_USER } from '../../graphql/users/register-internal-user';
import { GET_USER_LIST } from '../../graphql/users/get-user-list';
import './Forms.scss';

class FirmRegistrationForm extends PureComponent {
  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={REGISTER_INTERNAL_USER}
        update={(cache, mutationResult) => {
          const query = cache.readQuery({
            query: GET_USER_LIST,
          });

          const userData = {
            ...query,
            users: {
              ...query.users,
              items: [mutationResult.data.registerInternalUser, ...query.users.items],
            },
          };

          cache.writeQuery({
            query: GET_USER_LIST,
            data: userData,
          });
        }}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          const { givenName, familyName } = data.registerInternalUser;

          message.success(
            <span className="u-standardcase">
              {givenName} {familyName} has been registered successfully
            </span>,
            4
          );

          /* Close modal */
          this.props.registerInternalUserModalVisible(false);
        }}
      >
        {(registerInternalUser, { loading, error, data }) => {
          return (
            <Form
              {...layout}
              name="internalUserRegistrationForm"
              className="internal-user-registration-form"
              layout="vertical"
              onFinish={(values) => {
                registerInternalUser({
                  variables: {
                    input: {
                      givenName: values.givenName,
                      familyName: values.familyName,
                      email: values.email,
                      password: values.password,
                    },
                  },
                });
              }}
            >
              <Form.Item
                label="First name"
                name="givenName"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="familyName"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="email"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid email address',
                  },
                  {
                    required: true,
                    message: 'Email address is required',
                  },
                ]}
              >
                <Input type="email" spellCheck="false" />
              </Form.Item>
              <Form.Item label="Password (optional)" name="password">
                <Input.Password
                  iconRender={(visible) => (visible ? 'hide' : 'show')}
                  spellCheck="false"
                />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Registering internal user' : 'Register internal user'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default FirmRegistrationForm;
