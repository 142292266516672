import gql from 'graphql-tag';

export const LIST_TASKS = gql`
  query ListLibraryTasks($includeUnpublished: Boolean) {
    libraryTasks(includeUnpublished: $includeUnpublished) {
      id
      name
      description
      matterTypes
      isPublished
    }
    __type(name: "MatterType") {
      name
      enumValues {
        name
      }
    }
  }
`;
