import gql from 'graphql-tag';

export const UPDATE_USER_ACCESS = gql`
  mutation UpdateUserAccess($input: UpdateUserAccessInput!) {
    updateUserAccess(input: $input) {
      id
      givenName
      middleName
      familyName
      fullName
      email
      phone
      blocked
    }
  }
`;
