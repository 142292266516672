import gql from 'graphql-tag';

export const UPDATE_FIRM_DETAILS = gql`
  mutation UpdateFirmDetails($input: UpdateFirmDetailsInput!) {
    updateFirmDetails(input: $input) {
      id
      companyNumber
      sraNumber
      name
      logoUrl
      seatCount
      vacantSeatCount
      occupiedSeatCount
    }
  }
`;
