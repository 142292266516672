import gql from 'graphql-tag';

export const REORDER_TASK_SECTION = gql`
  mutation ReorderTaskSections($input: ReorderTaskSectionsInput!) {
    reorderTaskSections(input: $input) {
      id
      name
      sections {
        id
        name
        description
      }
    }
  }
`;
