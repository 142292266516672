import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import { MoreOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Popover, message, Modal } from 'antd';
import RenameTaskSectionForm from '../Forms/RenameTaskSectionForm';
import UpdateTaskSectionDescForm from '../Forms/UpdateTaskSectionDescForm';
import UpdateTaskSectionAdviceForm from '../Forms/UpdateTaskSectionOfficialAdviceForm';
import ReorderTaskSectionForm from '../Forms/ReorderTaskSectionForm';
import TaskQuestionForm from '../Forms/TaskQuestionForm';
import TaskQuestion from '../TaskDetails/TaskQuestion';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import { ReactComponent as IconDescription } from '../../assets/images/icon-description.svg';
import { ReactComponent as IconAdvice } from '../../assets/images/icon-advice.svg';
import { REMOVE_TASK_SECTION } from '../../graphql/tasks/remove-task-section';
import './TaskSectionPanel.scss';

class TaskSectionPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      TaskQuestionModalVisible: false,
      renameTaskSectionModalVisible: false,
      showingMore: false,
    };
  }

  toggleQuestionModal = () => {
    this.setState((prevState) => {
      return { TaskQuestionModalVisible: !prevState.TaskQuestionModalVisible };
    });
  };

  renameTaskSectionModalVisible = () => {
    this.setState((prevState) => {
      return { renameTaskSectionModalVisible: !prevState.renameTaskSectionModalVisible };
    });
  };

  updateTaskSectionDescModalVisible = () => {
    this.setState((prevState) => {
      return { updateTaskSectionDescModalVisible: !prevState.updateTaskSectionDescModalVisible };
    });
  };

  updateTaskSectionOfficialAdviceModalVisible = () => {
    this.setState((prevState) => {
      return {
        updateTaskSectionOfficialAdviceModalVisible: !prevState.updateTaskSectionOfficialAdviceModalVisible,
      };
    });
  };

  reorderTaskSectionModalVisible = () => {
    this.setState((prevState) => {
      return { reorderTaskSectionModalVisible: !prevState.reorderTaskSectionModalVisible };
    });
  };

  createQuestions = (sectionData) => {
    const { questions } = sectionData;
    const questionsOutput = questions.map((questionData) => {
      return (
        <TaskQuestion
          key={questionData.id}
          taskData={this.props.taskData}
          questionData={questionData}
          sectionData={sectionData}
        />
      );
    });
    return questionsOutput;
  };

  componentDidMount() {
    const sectionName = this.refs.sectionName;

    const showHideSeeMoreBtn = () => {
      const seeMoreBtn = sectionName.parentElement.querySelector(
        '.ant-collapse-header-text__see-more-btn'
      );

      if (sectionName.clientWidth < sectionName.scrollWidth) {
        seeMoreBtn.style.display = 'block';
      }

      if (sectionName.clientWidth >= sectionName.scrollWidth) {
        seeMoreBtn.style.display = 'none';
      }

      if (sectionName.clientWidth >= sectionName.scrollWidth && this.state.showingMore === true) {
        seeMoreBtn.style.display = 'block';
      }

      if (sectionName.clientHeight < 34 && this.state.showingMore === true) {
        seeMoreBtn.style.display = 'none';
        sectionName.classList.toggle('ant-collapse-header-text--truncated');

        this.setState((prevState) => {
          return {
            showingMore: !prevState.showingMore,
          };
        });
      }
    };

    showHideSeeMoreBtn();

    window.addEventListener('resize', showHideSeeMoreBtn);
  }

  render() {
    const { Panel } = Collapse;

    return (
      <>
        <Collapse bordered={false}>
          <Panel
            header={
              <>
                <div
                  ref="sectionName"
                  className="ant-collapse-header-text ant-collapse-header-text--truncated"
                >
                  {this.props.sectionData.name}
                </div>
                <Button
                  type="link"
                  className="ant-collapse-header-text__see-more-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.refs.sectionName.classList.toggle('ant-collapse-header-text--truncated');
                    this.setState((prevState) => {
                      return {
                        showingMore: !prevState.showingMore,
                      };
                    });
                  }}
                >
                  {this.state.showingMore ? 'See less' : 'See more'}
                </Button>
              </>
            }
            key={this.props.sectionData.id}
            extra={
              <>
                <PlusOutlined
                  className="add-question-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleQuestionModal();
                  }}
                />
                <Popover
                  content={
                    <>
                      {/* Rename section */}
                      <Button
                        type="link"
                        className="p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.renameTaskSectionModalVisible();
                        }}
                      >
                        Rename section
                      </Button>

                      {/* Update section description */}
                      <Button
                        type="link"
                        className="p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.updateTaskSectionDescModalVisible();
                        }}
                      >
                        Update section description
                      </Button>

                      {/* Update section advice */}
                      <Button
                        type="link"
                        className="p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.updateTaskSectionOfficialAdviceModalVisible();
                        }}
                      >
                        Update section advice
                      </Button>

                      {/* Reorder section */}
                      {this.props.taskData.sectionCount > 1 && (
                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.reorderTaskSectionModalVisible();
                          }}
                        >
                          Reorder section
                        </Button>
                      )}

                      {/* Allow removal of task section if there is more than one section */}
                      {this.props.taskData.sectionCount > 1 ? (
                        <Mutation
                          mutation={REMOVE_TASK_SECTION}
                          variables={{
                            input: {
                              taskId: this.props.taskData.id,
                              sectionId: this.props.sectionData.id,
                            },
                          }}
                          onError={(data) => {
                            message.error(`Sorry, an error occurred`, 4);
                          }}
                          onCompleted={(data) => {
                            message.success(`the section has been deleted successfully`, 4);
                          }}
                        >
                          {(removeSection, { loading, error, data }) => {
                            const confirmRemoveTask = () => {
                              Modal.confirm({
                                title: 'Remove section',
                                icon: <ExclamationCircleOutlined />,
                                content: `Are you sure you want to remove the '${this.props.sectionData.name}' section?`,
                                centered: true,
                                okText: 'Remove',
                                cancelText: 'Cancel',
                                onOk: removeSection,
                              });
                            };
                            return (
                              <Button
                                type="link"
                                className="p-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmRemoveTask();
                                }}
                              >
                                Remove section
                              </Button>
                            );
                          }}
                        </Mutation>
                      ) : null}
                    </>
                  }
                  trigger="click"
                  placement="left"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <MoreOutlined style={{ fontSize: 20 }} />
                </Popover>
              </>
            }
          >
            <div className="questions-list">
              {this.props.sectionData.description && (
                <div className="task-details__section-desc">
                  <span className="task-details__section-desc-heading">
                    <IconDescription className="task-details__section-desc-icon" />
                    <b>Description</b>
                  </span>
                  <p className="u-whitespace-prewrap">{this.props.sectionData.description}</p>
                </div>
              )}
              {this.props.sectionData.officialAdvice && (
                <div className="task-details__section-advice">
                  <span className="task-details__section-advice-heading">
                    <IconAdvice className="task-details__section-advice-icon" />
                    <b>Official advice</b>
                  </span>
                  <p className="u-whitespace-prewrap">{this.props.sectionData.officialAdvice}</p>
                </div>
              )}
              {this.props.sectionData.questions.length > 0 ? (
                this.createQuestions(this.props.sectionData)
              ) : (
                <div className="question-empty">
                  this section has no questions yet,{' '}
                  <button
                    className="question-empty__add-question-btn"
                    onClick={this.toggleQuestionModal}
                  >
                    add one now?
                  </button>
                </div>
              )}
            </div>
          </Panel>
        </Collapse>

        {/* Add task question modal */}
        <Modal
          className="task-question-modal"
          title="Add question"
          centered
          destroyOnClose={true}
          visible={this.state.TaskQuestionModalVisible}
          onCancel={this.toggleQuestionModal}
          footer={null}
          width="1100px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <TaskQuestionForm
            closeQuestionModal={this.toggleQuestionModal}
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
          />
        </Modal>

        {/* Rename task section modal */}
        <Modal
          title="Rename task section"
          centered
          destroyOnClose={true}
          visible={this.state.renameTaskSectionModalVisible}
          onCancel={this.renameTaskSectionModalVisible}
          footer={null}
          width="500px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <RenameTaskSectionForm
            renameTaskSectionModalVisible={this.renameTaskSectionModalVisible}
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
            sectionName={this.props.sectionData.name}
          />
        </Modal>

        {/* Update task section description modal */}
        <Modal
          title="Update section description"
          centered
          destroyOnClose={true}
          visible={this.state.updateTaskSectionDescModalVisible}
          onCancel={this.updateTaskSectionDescModalVisible}
          footer={null}
          width="500px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <UpdateTaskSectionDescForm
            updateTaskSectionDescModalVisible={this.updateTaskSectionDescModalVisible}
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
            sectionDescription={this.props.sectionData.description}
          />
        </Modal>

        {/* Update task section official advice modal */}
        <Modal
          title="Update section official advice"
          centered
          destroyOnClose={true}
          visible={this.state.updateTaskSectionOfficialAdviceModalVisible}
          onCancel={this.updateTaskSectionOfficialAdviceModalVisible}
          footer={null}
          width="500px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <UpdateTaskSectionAdviceForm
            updateTaskSectionOfficialAdviceModalVisible={
              this.updateTaskSectionOfficialAdviceModalVisible
            }
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
            officialAdvice={this.props.sectionData.officialAdvice}
          />
        </Modal>

        {/* Reorder task section modal */}
        <Modal
          title="Reorder task section"
          centered
          destroyOnClose={true}
          visible={this.state.reorderTaskSectionModalVisible}
          onCancel={this.reorderTaskSectionModalVisible}
          footer={null}
          width="500px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <ReorderTaskSectionForm
            reorderTaskSectionModalVisible={this.reorderTaskSectionModalVisible}
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
            sectionCount={this.props.taskData.sectionCount}
          />
        </Modal>
      </>
    );
  }
}

export default TaskSectionPanel;
