import gql from 'graphql-tag';

export const ADD_FIRM_MEMBER = gql`
  mutation AddFirmMember($input: AddFirmMemberInput!) {
    addFirmMember(input: $input) {
      id
      employeeNumber
      jobTitle
      office
      role
      isActive
      activeSince
      status
      firm {
        occupiedSeatCount
        vacantSeatCount
      }
      professional {
        id
        status
        referenceNumber
        type
        firstName
        preferredName
        lastName
        emailAddress
        pictureUrl
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
    }
  }
`;
