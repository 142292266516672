import gql from 'graphql-tag';

export const REORDER_QUESTIONS = gql`
  mutation ReorderQuestions($input: ReorderQuestionsInput!) {
    reorderQuestions(input: $input) {
      id
      name
      questions {
        id
        text
      }
    }
  }
`;
