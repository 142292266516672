import gql from 'graphql-tag';

export const MARK_NOTIFICATION_READ = gql`
  mutation MarkNotificationRead($input: MarkNotificationReadInput!) {
    markNotificationRead(input: $input) {
      id
      context
      type
      title
      body
      pictureUrl
      path {
        id
        type
        name
      }
      occurredOn
      readAt
      isRead
    }
  }
`;
