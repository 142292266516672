import gql from 'graphql-tag';

export const CHANGE_FIRM_MEMBER_STATUS = gql`
  mutation ChangeFirmMemberStatus($input: ChangeFirmMemberStatusInput!) {
    changeFirmMemberStatus(input: $input) {
      id
      firm {
        id
        name
        seatCount
        vacantSeatCount
        occupiedSeatCount
      }
      professional {
        id
        status
        firstName
        preferredName
        lastName
        emailAddress
        pictureUrl
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
      role
      isActive
      activeSince
      status
    }
  }
`;
